import React from "react";
import PropTypes from "prop-types";

/**
 * Tag Section. List is displayed as rounded a href links.
 * @param title - Section Headline
 * @param items - Array of objects containing { href: string, name: string }
 */
const Tags = ({ title, items }) => {
  return (
    <div className="tags">
      <div className="tags__title">{title}</div>
      <ul className="tags__list">
        {items.map(item => (
          <li key={item.category_title}>
            <a
              href={item.category_url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {item.category_title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

Tags.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Tags;
